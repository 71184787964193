







import Vue from "vue";
import AssessmentHeader from "@/components/assessments/AssessmentHeader.vue";
import AssessmentBody from "@/components/assessments/AssessmentBody.vue";

export default Vue.extend({
  name: "CandidateAssessment",
  components: { AssessmentBody, AssessmentHeader }
});
