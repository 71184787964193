import { render, staticRenderFns } from "./AssessmentBody.vue?vue&type=template&id=6282d209&scoped=true&"
import script from "./AssessmentBody.vue?vue&type=script&lang=ts&"
export * from "./AssessmentBody.vue?vue&type=script&lang=ts&"
import style0 from "./AssessmentBody.vue?vue&type=style&index=0&id=6282d209&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6282d209",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCol,VImg,VRadio,VRadioGroup,VRow,VSkeletonLoader})
